@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

/* .wrapper {
    background-color: #000;
} */

.container {
  max-width: 100%;
}
.text-center-div {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
/* Header Css Start */
.custom-header {
  max-width: 1550px;
  margin: 0 auto;
  z-index: 99;
  top: 24px;
}
.about-page .header a.product {
  color: #000;
}
.custom-header .header {
  align-items: center;
  display: flex;
  width: 100%;
}

.custom-header .navbar-collapse {
  gap: 45px;
}

.custom-header .header a.product img {
  width: 23px;
  height: 23px;
  margin-right: 15px;
}

.custom-header .header a.nav-link {
  padding: 0px;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding: 0px 22px;
  display: flex;
}

.custom-header .header .dropdown-menu {
  min-width: 17rem;
  padding: 0px;
  margin-top: 25px;
  margin-left: 30px;
}

.custom-header .header a.dropdown-toggle::after {
  border: none;
  background-image: url(../../../public/img/drop.svg);
  background-repeat: no-repeat;
  height: 18px;
  margin: 0px 0px 0px 3px;
  width: 15px;
}

.about-page .header a.dropdown-toggle::after{
  background-image: url(../../../public/img/dropwhite.svg);
}

.custom-header .header .dropdown-menu a.nav-link {
  color: #000;
  padding: 15px 20px;
  font-size: 16px;
  /* border-bottom: 1px solid #c7c7c7; */
  text-transform: capitalize;
  align-items: center;
}
.custom-header .header .dropdown-menu a.nav-link::before {
  width: 25px;
  height: 25px;
  content: "";
  /* border: 1px solid #000; */
  background-color: #eeeeee;
  margin-right: 10px;
  border-radius: 5px;
}
.custom-header .header .dropdown-menu a.nav-link:hover {
  /* background-color: #ededed; */
  /* box-shadow: 1px 1px #ededed; */
}

.custom-header .header .dropdown-menu a.nav-link:last-child {
  border: none;
}

.custom-header .header a.product {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: end;
  padding: 0px !important;
}
.about-page .header a.product,
.about-page .header a.nav-link {
  color: #000;
}
/* Hero Css Start */
section.Hero {
  min-height: 900px;
  /* max-width: 1600px; */
  /* background-color: #000; */
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  /* opacity: .8; */
  margin-top: -64px;
  background-image: url(../../../public/img/BG.png);
  background-repeat: no-repeat;
  background-size: cover;
}
section.Hero .container {
  max-width: 1600px;
}
section.Hero video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.9;
}

.border-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

section.Hero .hero-center-content {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  position: relative;
  z-index: 9;
}

section.Hero .hero-center-content h1 {
  font-size: 52px;
  font-weight: 500;
  color: #fff;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  max-width: 1000px;
  margin-bottom: 35px;
}

section.Hero .hero-center-content button {
  /* border: 1px solid #000; */
  font-family: "Inter", sans-serif;
  color: #000;
  padding: 9px 30px;
  background: linear-gradient(90deg, #111322 50%, #fff 0);
  background-position: 100% 100%;
  transition: all 0.5s ease-out;
  background-size: 201% 100%;
}
section.Hero .hero-center-content button:hover {
  background-position: 0 100%;
  /* border: 1px solid #fff; */
  color: #fff;
}

/* .send-mess {
    align-items: center;
    align-self: stretch;
    background: #151c37;
    background: linear-gradient(90deg, #fff 50%, #111322 0);
    background-position: 100% 100%;
    background-size: 201% 100%;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 #101828;
    color: #fcfcfd;
    font-family: "Inter", sans-serif;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    justify-content: center;
    line-height: normal;
    padding: 12px 18px;
    transition: all .5s ease-out;
}

.send-mess:hover {
    background-position: 0 100%;
    border: 1px solid #111322;
    color: #111322;
} */

section.Hero .hero-bottom-content {
  position: absolute;
  bottom: 45px;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  width: 100%;
  max-width: 1600px;
  align-items: end;
  padding: 0px 40px;
}

section.Hero .hero-bottom-content .user ul {
  display: flex;
}

section.Hero .hero-bottom-content .user ul li:nth-child(1) {
  margin: 0px;
}

section.Hero .hero-bottom-content .user ul li {
  /* margin-left: -10px; */
  margin-left: 20px;
}

section.Hero .hero-bottom-content .user .content {
  display: flex;
  align-items: center;
}

section.Hero .hero-bottom-content .user .content h4 {
  margin: 0px;
  font-size: 50px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-left: 10px;
}

section.Hero .hero-bottom-content .user .content p {
  margin: 0px;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  max-width: 150px;
  margin-left: 10px;
}

section.Hero .hero-bottom-content .social ul {
  display: flex;
  gap: 20px;
}

section.Hero .hero-bottom-content .partner {
  display: flex;
  align-items: end;
  gap: 30px;
}

section.Hero .hero-bottom-content .partner .content {
  max-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

section.Hero .hero-bottom-content .partner .img img {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
section.Hero .hero-bottom-content .partner .img img:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  transform: scale(1.05, 1.05);
}

section.Hero .hero-bottom-content .partner .content p {
  font-size: 12px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  max-width: 150px;
}

section.Hero .hero-bottom-content .partner .content a {
  font-size: 24px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  line-height: 28px;
}

section.Hero .hero-bottom-content .partner .content a.mail {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  text-decoration: none;
  /* text-transform: uppercase; */
}

/* About css start */
section.About {
  min-height: 500px;
  /* max-width: 1600px; */
  background-color: #fff;
  margin: auto;
  display: flex;
  align-items: end;
  position: relative;
  justify-content: center;
  padding: 0px 102px;
}
section.About .container {
  max-width: 1600px;
}
section.About .left-content {
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right;
  padding: 50px 50px 50px 0px;
  border-right: 1px solid #c7c7c7;
}

section.About .left-content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 30px;
}

section.About .left-content h4 img {
  margin-right: 10px;
}

section.About .left-content h1 {
  font-family: "Inter", sans-serif;
  font-size: 45px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 510px;
  margin: 0px;
}

section.About .right-content p {
  padding: 40px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #000;
}

section.About .right-content .heading {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #c7c7c7;
  padding: 58px 0px 0px 40px;
}

section.About .right-content .heading h4 {
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0px;
  text-transform: uppercase;
  margin-top: 20px;
}

section.About .right-content .heading img {
  /* width: 100%; */
  max-width: 24%;
}

section.About .col-xl-5,
section.About .col-xl-7 {
  padding: 0px;
}

/* Research css Start  */
section.Research {
  min-height: 894px;
  max-width: 1600px;
  background-color: #000;
  margin: auto;
  position: relative;
}

section.Research .col-xl-3 {
  padding: 0px;
}

section.Research .col-xl-6 {
  padding: 0px;
}

.sectionheading {
  border-right: 1px solid #303132;
  border-bottom: 1px solid #303132;
  min-height: 282px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 75px;
  justify-content: center;
}

.sectionheading h4 {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 52px;
  font-weight: 500;
  margin: 0px;
  margin-top: 10px;
  text-transform: uppercase;
}

.vision {
  border-right: 1px solid #303132;
  border-bottom: 1px solid #303132;
  min-height: 282px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.vision video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.vision .content {
  z-index: 9;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}

.vision .content h4 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0px;
  color: #fff;
}

.vision .content p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #737373;
}

.model {
  border-bottom: 1px solid #303132;
  min-height: 282px;
  display: flex;
  flex-direction: column;
  padding: 0px 75px;
  justify-content: center;
  gap: 20px;
}

.model h4 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0px;
  color: #fff;
}

.model p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #737373;
}

.explore {
  border-right: 1px solid #303132;
  min-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.explore a {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  margin-top: 20px;
  display: block;
  color: #fff;
}

.specialized {
  border-right: 1px solid #303132;
  min-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specialized video {
  border-radius: 190px;
  width: 660px;
  height: 282px;
  object-fit: cover;
}

.specialized .content {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-top: 50px;
}

.specialized .content h4 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0px;
  color: #fff;
  margin-bottom: 10px;
}

.specialized .content p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #737373;
  max-width: 446px;
}

.ai {
  min-height: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai .content {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  margin-top: 50px;
  padding: 0px 75px;
}

.ai .content h4 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin: 0px;
  color: #fff;
  margin-bottom: 10px;
}

.ai .content p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: #737373;
}

/* Partner css start  */
section.Partner {
  min-height: 780px;
  max-width: 1600px;
  background-color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0px 102px;
}

section.Partner .left-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.Partner .left-content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
}

section.Partner .left-content h4 img {
  margin-right: 10px;
}

section.Partner .left-content h1 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 440px;
  margin-bottom: 20px;
}

section.Partner .left-content p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}

section.Partner .center-content {
  background-color: #01564a;
  padding: 40px 20px 20px 20px;
}

section.Partner .center-content .content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

section.Partner .center-content .content h4 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin: 0px;
  max-width: 199px;
}

section.Partner .center-content .content .logo h1 {
  font-family: "Inter", sans-serif;
  font-size: 51px;
  color: #c9ff4f;
  font-weight: 700;
  margin: 0px;
  line-height: 51px;
  margin-top: 10px;
}

section.Partner .center-content ul {
  display: flex;
  gap: 10px;
  margin: 0px;
  margin-bottom: 5px;
}

section.Partner .center-content ul li {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

section.Partner .center-content p {
  font-family: "Inter", sans-serif;
  color: #c9ff4f;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
}

section.Partner .bottomimage {
  background-color: #01564a;
}

section.Partner .bottomimage img {
  width: 100%;
}

section.Partner .right-content .toplayer {
  background-color: #013df4;
  padding: 30px 25px;
}

section.Partner .right-content .centerlayer {
  background-color: #2ebaec;
  min-height: 101px;
}

section.Partner .right-content .bottomlayer {
  background-color: #000;
  padding: 30px;
  min-height: 318px;
}

section.Partner .right-content .bottomlayer h6 {
  color: #737373;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 40px;
  text-transform: uppercase;
}

section.Partner .right-content .bottomlayer h4 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  max-width: 150px;
}

section.Partner .right-content .bottomlayer h1 {
  font-family: "Inter", sans-serif;
  font-size: 51px;
  font-weight: 700;
  margin: 0px;
  background: linear-gradient(100deg, #9de6ff 0%, #a399ed 49%, #e26fe0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 51px;
}

/* Product Css Start  */

section.Product {
  max-width: 1600px;
  background-color: #fff;
  margin: auto;
  padding: 0px 102px;
}

section.Product .headercontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 20px;
}

section.Product .headercontent .productlogo {
  position: relative;
}

section.Product .headercontent img {
  z-index: 0;
  position: relative;
}

section.Product .headercontent img.yellowcircle {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

section.Product .headercontent h4 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: #000;
  font-weight: 700;
  margin: 0px;
  max-width: 648px;
  text-transform: uppercase;
}

section.Product .content {
  border-bottom: 1px solid #c7c7c7;
  padding: 70px 0px;
}

section.Product .content .firstheading h4 {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

section.Product .content .secondheading h4 {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 52px;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 10px;
}

section.Product .content .secondheading ul {
  margin: 0px;
  max-width: 584px;
}

section.Product .content .secondheading ul li {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
  margin-bottom: 5px;
}

section.Product .content .thirdheading p {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

section.Product .content .thirdheading a {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
}

section.Product .content .thirdheading a img {
  margin-left: 10px;
}

/* Mission Css Start  */

section.Mission {
  min-height: 780px;
  /* max-width: 1600px; */
  background-color: #fff;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0px 102px;
}
section.Mission .container {
  max-width: 1396px;
}
section.Mission .missionlogo {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 55%;
}

section.Mission .content {
  margin: 0px 0px 0px 15px;
}

section.Mission .content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
}

section.Mission .content h1 {
  font-family: "Inter", sans-serif;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
}

section.Mission .content p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}

section.Mission .mission1 {
  margin-top: 40px;
}

section.Mission .mission2 {
  margin-top: -40px;
}

section.Mission .content h4 img {
  margin-right: 10px;
}

section.Mission .secondrow {
  margin-top: 70px;
}

/* Team Css Start  */

section.Team {
  /* min-height: 1220px; */
  /* max-width: 1600px; */
  background-color: #000;
  margin: auto;
  position: relative;
  padding-top: 120px;
  /* background-image: url(../../../public/img/team.svg); */
  background-repeat: no-repeat;
  background-position: bottom center;
}
section.Team .container {
  max-width: 1600px;
}
/* section.Team .content {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
} */

section.Team .content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}

section.Team .content h4 img {
  margin-right: 10px;
}

section.Team .content h1 {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #fff;
  text-align: left;
  text-align: -webkit-left;
  text-align: -moz-left;
}

section.Team .content h1 video {
  width: 100%;
  max-width: 380px;
  margin-left: 30px;
}

section.Team .content h1 span {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: #6bd07f;
  border-radius: 100px;
  width: 90px;
  text-align: center;
  display: inline-block;
}

section.Team .content p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  max-width: 543px;
  margin: 0px;
  /* text-align: left;
    text-align: -webkit-left;
    text-align: -moz-left; */
}
.team {
  border: none;
  border-radius: 0px;
  margin-bottom: 20px;
  background: none;
}
.team .img {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 100%;
}

.team .img img {
  transition: all 1s;
  transition: opacity 0.5s, transform 0.5s;
  width: 100%;
  object-fit: cover;
}
.team:hover .img img {
  filter: alpha(opacity = 30);
  opacity: 0.3;
  -moz-opacity: 0.3;
  transform: scale(1.3);
}
.team .card-content {
  padding-inline: 10px;
  padding-bottom: 10px;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.team .card-content h4 {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 4px;
}
.team .card-content label {
  /* -webkit-text-fill-color: #0000; */
  /* background: linear-gradient(136deg, #7d89b0, #003cff 98.85%); */
  font-family: "Inter", sans-serif;
  /* background-clip: text; */
  /* -webkit-background-clip: text; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
  color: #fff;
}
.team .card-content ul {
  display: flex;
  gap: 20px;
  margin: 0;
  justify-content: center;
}
/* Press Release Css Start  */
section.Press {
  /* max-width: 1600px; */
  background-color: #fff;
  margin: auto;
  /* padding: 122px 102px; */
  /* position: relative; */
  /* overflow: hidden; */
}
section.Press .container {
  max-width: 1396px;
  position: relative;
  overflow: hidden;
  padding: 122px 20px;
}
section.Press .left-content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
}

section.Press .left-content h4 img {
  margin-right: 10px;
}

section.Press .left-content h1 {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 750px;
  margin: 0px;
}
section.Press-List .left-content h1 {
  max-width: 100%;
}

section.Press .videothumbnail {
  margin-top: -30px;
}

section.Press .videothumbnail a {
  text-decoration: none;
  color: #000;
}

section.Press .videothumbnail a h4 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  margin-top: 10px;
  max-width: 140px;
}

section.Press video {
  position: absolute;
  right: -12%;
  border-radius: 50%;
  width: 660px;
  height: 338px;
  width: 338px;
  object-fit: cover;
}

section.Press .horizontimeline ul {
  display: -moz-box;
  display: -webkit-box;
  overflow-x: auto;
  width: 100%;
  padding: 100px 0px;
}

section.Press .horizontimeline ul::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
  height: 10px;
}

section.Press .horizontimeline ul::-webkit-scrollbar {
  height: 5px;
  background-color: #e90c0c;
}

section.Press .horizontimeline ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #000;
  width: 100px;
}

section.Press .horizontimeline ul li {
  margin-right: 70px;
  width: 353px;
}

section.Press .horizontimeline ul li:last-child {
  margin: 0px;
}

section.Press .horizontimeline ul li a {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  text-decoration: none;
  font-weight: 300;
  color: #000;
  text-transform: uppercase;
}

section.Press .horizontimeline ul li label {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #c7c7c7;
  width: 100%;
}

section.Press .horizontimeline ul li p {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

section.Press .horizontimeline ul li a img {
  margin-left: 10px;
}

/* Press List Css Start  */

section.PressList {
  max-width: 1600px;
  /* background-color: #fff; */
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: 0px 102px;
  margin-top: 200px;
  border-bottom: 1px solid #fff;
  padding-bottom: 100px;
}

section.PressList .content {
  min-height: 400px;
  border: 1px solid #c7c7c7;
  padding-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;

  border: 0.921px solid #fff;
  border-radius: 14.742px;
  transition: border 0.5s ease;
}

section.PressList .content:hover {
  border: 0.921px solid #9770ff;
  box-shadow: 0 3.685px 114.249px 9.214px #000000;
}

section.PressList .content img {
  /* border-radius: 20px; */
}

section.PressList .content a img {
  margin-left: 10px;
  border-radius: 0px;
}

/* Footer Css Start  */
footer.custom-footer {
  max-width: 1600px;
  background-color: #000;
  margin: auto;
  padding: 50px 102px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #c7c7c7;
}

footer.custom-footer .left-content h4 {
  font-family: "Inter", sans-serif;
  font-size: 142px;
  color: #fff;
  margin: 0px;
  text-transform: uppercase;
}

footer.custom-footer .left-content h4 img {
  margin-left: 30px;
}

footer.custom-footer .quicklinks {
  margin-top: 30px;
}

footer.custom-footer .quicklinks h4 {
  color: #fff;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}

footer.custom-footer .quicklinks ul {
  display: flex;
  gap: 45px;
}

footer.custom-footer .quicklinks ul li a {
  color: #fff;
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

footer.custom-footer .right-content h4 {
  color: #fff;
}

footer.custom-footer .right-content p {
  color: #fff;
}

.bottom-footer {
  max-width: 1600px;
  background-color: #000;
  margin: auto;
  padding: 20px 102px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #c7c7c7;
}

.bottom-footer p {
  color: #fff;
}

/* Contact Css Start  */
.Hero-6 {
  background-color: #fff;
  margin-top: 90px;
  padding-bottom: 96px;
}

.Hero-6 .Hero-Content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 90px;
  position: relative;
}

.Hero-6 .Hero-Content .Frame-Route {
  align-items: center;
  background: #f6f6f6;
  border-radius: 16px;
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 10px 16px;
}

.Hero-6 .Hero-Content .Frame-Route h4 {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  color: #9770ff;
}

.Hero-6 .Hero-Content .Frame-Route h4 a {
  font-size: 16px;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  color: #9770ff;
}

.Hero-Content .Content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.Hero-6 .Hero-Content .Content h4 {
  color: #111322;
  font-family: "Inter", sans-serif;
  font-size: 63px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin: 0;
  transition: 0.4s ease;
}

.Hero-6 .Hero-Content .Content p {
  font-family: "Inter", sans-serif;
  color: #475467;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
  width: 700px;
}

.Hero-6 .Custom-Form {
  display: flex;
  justify-content: center;
  padding-top: 96px;
}

.Hero-6 .Custom-Form form {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 480px;
  width: 480px;
}

.Hero-6 .Custom-Form form .Name {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  width: 100%;
}

.Hero-6 .Custom-Form form .Name div {
  width: 100%;
}

.Hero-6 .Custom-Form form label {
  font-family: "Inter", sans-serif;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 6px;
}

.Hero-6 .Custom-Form form input.form-control,
.Hero-6 .Custom-Form form textarea.form-control {
  background: #fff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 #1018280d;
  padding: 10px 14px;
  transition: border 0.5s ease;
}

.Hero-6 .Custom-Form form button {
  margin-top: 8px;
}

.send-mess {
  align-items: center;
  align-self: stretch;
  background: #151c37;
  background: linear-gradient(90deg, #fff 50%, #111322 0);
  background-position: 100% 100%;
  background-size: 201% 100%;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 #101828;
  color: #fcfcfd;
  font-family: "Inter", sans-serif;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: normal;
  padding: 12px 18px;
  transition: all 0.5s ease-out;
}

.send-mess:hover {
  background-position: 0 100%;
  border: 1px solid #111322;
  color: #111322;
}

.press-btn {
  font-weight: 400;
}
.press-btn:hover {
  background-position: 0 100%;
  border: 1px solid #111322;
  color: #111322;
}

section.Map {
  background: #f9fafb;
  gap: 64px;
  padding-bottom: 96px;
  padding-top: 96px;
  position: relative;
}

section.Map,
section.Map .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

section.Map .frame .grid {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 96px;
}

section.Map .frame .grid .left-content {
  align-items: center;
  display: flex;
  max-width: 532px;
}

section.Map .frame .grid .left-content .colab-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24.8px;
  max-width: 532px;
  width: 532px;
}

section.Map .frame .grid .left-content .colab-content .Heading {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

section.Map .frame .grid .left-content .colab-content .Heading h4 {
  color: #111322;
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.28px;
  line-height: 43px;
  margin: 0;
}

section.Map .frame .grid .left-content .colab-content .Desc {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

section.Map .frame .grid .left-content .colab-content .Desc p {
  color: #1a2b3b;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  max-width: 460px;
}

section.Map .frame .grid .right-content {
  align-items: flex-start;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  gap: 32px;
}

section.Map .frame .grid .right-content .list {
  align-self: stretch;
  display: flex;
  gap: 16px;
  max-width: 560px;
  min-width: 240px;
}

section.Map .frame .grid .right-content .list .text h4 {
  font-family: "Inter", sans-serif;
  color: #111322;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.96px;
  line-height: 28px;
  margin: 0 0 8px;
}

section.Map .frame .grid .right-content .list .text p {
  font-family: "Inter", sans-serif;
  color: #111322;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
/* About Page Css Start */
section.AboutHero {
  background-color: #fff;
  /* margin-top: 90px; */
  margin-top: -64px;
  padding: 96px 0px;
}
section.AboutHero .container {
  max-width: 1396px;
}
section.AboutHero .Hero-Content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 90px;
  position: relative;
}
section.AboutHero .Hero-Content .Frame-Route h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}
section.AboutHero .Hero-Content .Frame-Route h4 img {
  margin-right: 10px;
}
section.AboutHero .Hero-Content .Content {
  max-width: 1078px;
}
section.AboutHero .Hero-Content .Content h4 {
  font-family: "Inter", sans-serif;
  font-size: 70px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px;
}
section.AboutHero .Hero-Content .Content p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  /* max-width: 670px; */
}
section.ImgSlide {
  margin-bottom: 200px;
}
section.ImgSlide .container {
  max-width: 1312px;
}
section.ImgSlide .content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 25px;
}
section.ImgSlide .content div:nth-child(odd) {
  margin-top: 66px;
}
section.ImgSlide .content img {
  width: 100%;
}
section.WhoWeAre {
  margin-bottom: 100px;
}
section.WhoWeAre .container {
  max-width: 1312px;
}
section.WhoWeAre .heading h4 {
  font-family: "Inter", sans-serif;
  font-size: 70.1px;
  font-weight: 500;
  line-height: 84.84px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: capitalize;
  margin: 0px;
}
section.WhoWeAre .heading p {
  font-family: "Inter", sans-serif;
  color: #888888;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  letter-spacing: -0.02em;
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
  position: relative;
  top: -15px;
}
section.WhoWeAre .desc p {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 45.57px;
  letter-spacing: -0.02em;
  color: #3c3c3c;
  margin: 0px;
}
section.Corporate {
  margin-bottom: 96px;
}
section.Corporate .container {
  max-width: 1312px;
}
section.Corporate .content h4 {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

section.Corporate .content p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.63px;
  letter-spacing: -0.02em;
  text-align: justify;
}
section.Directors .container {
  max-width: 1312px;
}

section.Directors .heading h4 {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-bottom: 70px;
}

section.Directors .team {
  text-align: center;
  text-align: -webkit-center;
  text-align: -moz-center;
}
section.Directors .team .img {
  width: 248px;
  height: 248px;
}
section.Directors .team .img img {
  width: 248px;
  height: 248px;
}
section.Directors .team .card-content h4 {
  color: #000;
}

section.Directors .team .card-content label {
  color: #000;
}
section.CompanyList .container {
  max-width: 1312px;
}
section.CompanyList .companies {
  margin-bottom: 60px;
}
section.CompanyList .companies .heading h4 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48.41px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 35px;
}
section.CompanyList .company-detail {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
section.CompanyList .company-detail .content ul {
  margin: 0px;
}
section.CompanyList .company-detail .content {
  max-width: 700px;
}
section.CompanyList .company-detail .content p {
  font-family: "Inter", sans-serif;
  font-size: 19.83px;
  font-weight: 400;
  text-align: left;
  color: #615c5c;
}
section.CompanyList .company-detail .content ul li {
  font-family: "Inter", sans-serif;
  font-size: 19.83px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #615c5c;
}
section.CompanyList .company-detail .link a {
  /* font-family: "Inter", sans-serif;
  font-size: 24.09px;
  font-weight: 400;
  line-height: 44.61px;
  text-align: left;
  color: #105cb6;
  text-decoration: none; */
}
section.Press-List .horizontimeline ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
section.Press-List .horizontimeline ul li {
  margin-bottom: 100px;
}
section.Committees {
  margin: 100px 0px;
}
section.Committees .container {
  max-width: 1312px;
}
section.Committees .heading h4 {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-bottom: 70px;
}
section.Committees .ComiteList h4 {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 35px;
  text-transform: uppercase;
  min-height: 72px;
}
section.Committees .ComiteList ul li {
  background: #001b790a;
  border-radius: 5px;
  padding: 15px;
  width: fit-content;
  min-width: 180px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
section.Committees .ComiteList ul li a {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: #111827;
}
section.Committees .ComiteList ul li a img {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  margin-right: 10px;
}
section.Chart .container {
  max-width: 1312px;
}
section.Chart {
  margin-bottom: 100px;
}
section.Interviews {
  margin-bottom: 100px;
}
section.Interviews .container {
  max-width: 1312px;
}
section.Interviews .heading h4 {
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 40px;
  border-bottom: 2px solid #d4d4d4;
}

section.Interviews ul.interviewlist li a {
  display: flex;
  flex-direction: row;
  gap: 30px;
  color: #000;
  text-decoration: none;
  margin-bottom: 40px;
}
section.Interviews ul.interviewlist li a label {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
section.Interviews ul.interviewlist li a h4 {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #000;
}
section.Interviews ul.interviewlist li a h5 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
section.Interviews ul.interviewlist li a span {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #8b8b8b;
}
section.Reports .container {
  max-width: 1312px;
  padding: 0px 20px;
}
section.Reports .horizontimeline ul {
  padding: 0px;
}
section.Reports .customtab ul {
  display: flex;
  justify-content: space-between;
}
section.Reports .customtab ul li {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
section.Reports .customtab ul li.react-tabs__tab--selected {
  border: none;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
section.Reports .customtab .react-tabs__tab-list {
  border-top: 1px solid #aaa;
  padding: 20px 0px;
}
section.Reports .slick-list .reportlist {
  margin-top: 50px;
  padding-right: 70px;
}
section.Reports .slick-list .reportlist label {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #aaa;
  width: 100%;
}
section.Reports .slick-prev {
  left: -50px;
}
section.Reports .slick-next {
  left: 100%;
}
section.Reports .slick-prev:before,
section.Reports .slick-next:before {
  color: #e5d8d8;
  font-size: 35px;
}

section.Reports .slick-list .reportlist h4 {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
section.Reports .slick-list .reportlist p {
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-bottom: 8px;
  width: 100%;
}
section.companycard {
  margin-bottom: 100px;
}
section.companycard .container {
  max-width: 1312px;
}
section.companycard .card {
  display: flex;
  height: 350px;
  padding: 55px 36px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 14.742px;
  border: 0.921px solid rgba(0, 0, 0, 0.05);
  transition: border 0.5s ease;
  background: rgba(255, 255, 255, 0.35);
}
section.companycard .card:hover {
  border: 0.921px solid #003cff;
  background: #fff;
  box-shadow: 0px 3.685px 114.249px 9.214px rgba(0, 0, 0, 0.04);
}
section.companycard .card h4 {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 48.41px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 20px;
}
section.companycard .card p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}
section.PrivacyContent {
  padding: 96px 0px;
}
section.PrivacyContent .content h4 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 48.41px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 20px;
}
section.PrivacyContent .content p {
  font-family: "Inter", sans-serif;
    font-size: 19.83px;
    font-weight: 400;
    text-align: left;
    color: #615c5c;
    margin-bottom: 20px;
}
section.PrivacyContent .content ul li{
  font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0px;
}
section.PrivacyContent .content table{
  background-color: transparent;
    width: 100%;
    margin-block-end: 15px;
    font-size: .9em;
    border-spacing: 0;
  font-family: "Inter", sans-serif;
  border-collapse: collapse;
}
section.PrivacyContent .content table td,section.PrivacyContent .content table th{
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid hsla(0, 0%, 50.2%, .5019607843);
}

section.PrivacyContent .content table tbody>tr:nth-child(odd) > td,section.PrivacyContent .content table tbody > tr:nth-child(odd) > th {
  background-color: hsla(0, 0%, 50.2%, .0705882353);
}
.openDisc{
  padding: 40px;
}
.openDisc h4{
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.openDisc p{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}



/* Responsive css start  */
.mobile {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .sectionheading {
    padding: 20px;
  }
}

@media only screen and (max-width: 1400px) {
  section.About .right-content .heading {
    flex-direction: column;
  }

  section.About .right-content .heading h4 {
    margin-bottom: 40px;
  }

  .specialized video {
    width: 91%;
  }

  section.Team {
    background-size: contain;
  }
}

@media only screen and (max-width: 1200px) {
  section.Reports .slick-next,
  section.Reports .slick-prev {
    display: none !important;
  }
  footer.custom-footer .quicklinks ul {
    flex-direction: column;
    gap: 20px;
  }

  section.Mission .mission1 {
    width: 100%;
  }

  section.Mission .mission2 {
    width: 100%;
  }

  section.Mission .mission3 {
    width: 100%;
  }

  section.Mission .mission4 {
    width: 100%;
  }

  section.Product .headercontent {
    flex-direction: column;
  }

  section.Partner {
    padding: 20px;
  }

  section.Partner .left-content {
    margin-bottom: 30px;
  }

  .model {
    min-height: 620px;
    border-right: 1px solid #303132;
  }

  .explore {
    border-bottom: 1px solid #303132;
  }

  section.About .left-content {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    border: none;
    border-bottom: 1px solid #c7c7c7;
  }

  section.About .right-content .heading {
    flex-direction: row;
    border: none;
  }

  section.About .left-content h1 {
    max-width: 100%;
  }

  .destkop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .custom-header .header a.product {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  section.CompanyList .company-detail .banner img {
    width: 100%;
    margin-bottom: 20px;
  }
  section.CompanyList .company-detail {
    flex-direction: column;
    gap: 0px;
  }
  section.CompanyList .companies {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #958e8e;
  }
  section.CompanyList .company-detail .content {
    max-width: 100%;
  }
  section.CompanyList .company-detail .content ul li {
    display: inline-block;
    border: 1px solid #000;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 991px) {
  section.Committees .ComiteList h4 {
    min-height: auto;
    margin-top: 20px;
  }
  section.Committees {
    margin: 50px 0px;
  }
  section.Committees .heading h4 {
    margin-bottom: 50px;
  }
  section.WhoWeAre .heading p {
    text-align: left;
    text-align: -webkit-left;
    text-align: -moz-left;
  }
  section.Map .frame .grid {
    flex-direction: column;
    gap: 50px;
  }

  section.PressList {
    margin-top: 100px;
  }

  .Hero-6 .Hero-Content .Content h4 {
    font-size: 40px;
    line-height: 40px;
  }

  .Hero-6 .Hero-Content .Content p {
    width: 100%;
  }

  section.Map .frame .grid .left-content .colab-content {
    width: 100%;
  }

  section.PressList .content h4 {
    text-align: center !important;
    text-align: -webkit-center !important;
    text-align: -moz-center !important;
  }

  .navbar-toggler {
    background-color: #fff;
  }

  .bottom-footer {
    padding: 20px;
  }

  section.PressList {
    padding: 20px;
  }

  footer.custom-footer {
    padding: 20px;
  }

  section.About .left-content h1 {
    font-size: 45px;
  }

  section.Press {
    padding: 20px;
  }

  section.About .left-content {
    padding: 0px;
    padding-bottom: 20px;
    margin-top: 20px;
  }

  section.About .right-content p {
    padding: 20px 0px;
  }

  section.Press video {
    opacity: 0.5;
  }

  section.Press .videothumbnail {
    margin-top: 30px;
  }

  section.Team {
    min-height: 740px;
  }

  section.Mission {
    padding: 20px;
  }

  section.Mission .mission1 {
    margin-bottom: 30px;
  }

  section.Mission .mission2 {
    margin: 0px;
    margin-bottom: 30px;
  }

  section.Mission .mission3 {
    margin-bottom: 30px;
  }

  section.Mission .mission4 {
    margin-bottom: 30px;
  }

  section.Mission .secondrow {
    margin-top: 0px;
  }

  section.Mission .content p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .model {
    min-height: 282px;
  }

  section.Product {
    padding: 20px;
  }

  .custom-header .header a.product {
    display: block;
    color: #000 !important;
    padding: 7px 30px !important;
    border-bottom: 1px solid #c7c7c7;
    font-weight: 400;
  }

  .mobile a.nav-link {
    color: #000 !important;
    padding: 7px 30px !important;
    border-bottom: 1px solid #c7c7c7;
  }

  .mobile .header {
    justify-content: space-between;
    position: relative;
  }

  .mobile .navbar-collapse {
    position: absolute;
    top: 64px;
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
  }

  section.About {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 880px) {
  section.Hero {
    /* min-height: 640px; */
    align-items: start;
  }

  section.Team .content h1 video {
    margin: 0px;
  }

  section.Hero .hero-center-content h1 {
    font-size: 40px;
    margin-top: 30px;
  }

  section.Hero .hero-center-content {
    top: 100px;
  }

  section.Hero .hero-bottom-content {
    align-items: start;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  section.Team {
    padding-top: 50px;
  }
  section.ImgSlide {
    margin-bottom: 100px;
  }
  section.About .right-content .heading {
    flex-direction: column;
  }

  section.Product .headercontent img {
    width: 100%;
  }

  section.Product .headercontent img.yellowcircle {
    width: auto;
    bottom: -40px;
  }

  section.Mission .content {
    margin: 0px;
  }
}
@media only screen and (max-width: 640px) {
  section.AboutHero .Hero-Content .Content h4 {
    font-size: 52px;
  }
  section.Interviews ul.interviewlist li a {
    flex-direction: column;
  }
  section.Interviews ul.interviewlist li a .image img {
    width: 100%;
  }
  section.ImgSlide .content {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }
  section.ImgSlide .content div:nth-child(odd) {
    margin-top: 0px;
  }
  section.ImgSlide {
    margin-bottom: 50px;
  }
  section.Corporate {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 540px) {
  section.Partner .center-content ul {
    flex-direction: column;
  }

  section.Partner .center-content .content {
    flex-direction: column;
    gap: 20px;
  }

  footer.custom-footer .left-content h4 {
    font-size: 30px;
  }

  footer.custom-footer .left-content h4 img {
    width: 50px;
  }
}
@media only screen and (max-width: 540px) {
  section.ImgSlide .content {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
  }
}
